<template>
	<!-- <v-sheet class="recurring-main-listing" style="height: calc(100vh - 88px)"> -->
	<div v-if="getPermission('contract:view')">
		<v-layout class="">
			<v-flex class="py-0">
				<div style="min-width: 150px; max-width: 250px">
					<!-- contractStatus -->
					<v-select
						v-model="status"
						class="listing-select"
						hide-details
						:disabled="pageLoading"
						:items="listingStatus"
						solo
						@change="statusFilterRows($event)"
						:menu-props="{ offsetY: true, contentClass: 'rounded-lg border grey' }"
					>
						<template #item="{ item }">
							<div class="d-flex align-center py-1">
								<div class="mr-2" style="min-width: 72px !important">
									<v-chip size="26" class="px-2" dark :color="item.status_color">
										{{ item.status_count }}
									</v-chip>
								</div>
								<div class="fw-600 text-uppercase">{{ item.text }}</div>
							</div>
						</template>
					</v-select>
				</div>
			</v-flex>

			<!-- <v-flex class="py-0">
				<div class="my-2 ml-4 d-flex" style="height: 35px !important">
					<div class="mr-4 py-2 px-3 rounded blue lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="getListing()">All </span>
					</div>
					<div class="mr-4 py-2 px-3 rounded blue lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Raised')">Raised : </span>
						<v-chip class="px-2 py-1" label color="blue white--text" small>
							{{ count.raisedRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded green lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Paid')">Paid : </span>
						<v-chip class="px-2 py-1" label color="green white--text" small>
							{{ count.paidRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded warning lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Unpaid')">Unpaid : </span>
						<v-chip class="px-2 py-1" label color="warning white--text" small>
							{{ count.unpaidRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded cyan lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Postponed')"
							>Postponed :
						</span>
						<v-chip class="px-2 py-1" label color="cyan white--text" small>
							{{ count.postponedRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded red lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Cancelled')"
							>Cancelled :
						</span>
						<v-chip class="px-2 py-1" label color="red white--text" small>
							{{ count.cancelledRecurring }}
						</v-chip>
					</div>
				</div>
			</v-flex> -->

			<v-flex class="py-0 my-auto d-flex text-right listing-right justify-content-end">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-flex class="flex d-flex justify-content-end pr-1 mt-2">
						<div class="mr-2 fw-600">Filter By</div>
						<div class="ml-3 inline-filter">
							<!-- style="max-width: 50% !important;" -->
							<v-autocomplete
								:items="customerData"
								v-model="customer_type"
								clearable
								item-text="company_name"
								:disabled="pageLoading"
								item-value="id"
								v-on:click:clear="(defaultFilter = {}), getListing()"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								hide-details
								placeholder="Customer"
								@change="commanFilter($event, 'customer')"
							>
								<template #item="{ item, attrs }">
									<div class="w-100 align-center py-1">
										<v-chip size="20" color="cyan" x-small dark label class="pa-1 mb-1 mr-2">
											<span class="fs-11 fw-600">{{ item.barcode }}</span>
										</v-chip>
										<div class="fw-600 text-uppercase">
											{{ item.company_name }}
										</div>
									</div>
								</template>
								<template #selection="{ item }">
									<div class="d-flex align-center">
										<v-chip size="20" color="cyan" x-small dark label class="pa-1 mb-1 mr-2">
											<span class="fs-11 fw-600">{{ item.barcode }}</span>
										</v-chip>
										<div class="fw-600 text-uppercase">
											{{ item.company_name }}
										</div>
									</div>
								</template>
							</v-autocomplete>
						</div>
						<div
							class="filterTagSelect ml-3 inline-filter"
							v-if="
								this.currentUser.role == 1 ||
								this.currentUser.role == 4 ||
								this.currentUser.role == 6 ||
								this.currentUser.role == 7 ||
								this.currentUser.role == 12 ||
								this.currentUser.role == 13
							"
						>
							<v-autocomplete
								:items="users"
								:menu-props="{ bottom: true, offsetY: true, contentClass: 'rounded-lg' }"
								outlined
								hide-details
								clearable
								@click:clear="(defaultFilter = {}), getListing()"
								item-text="display_name"
								:disabled="pageLoading"
								style="min-width: 230px !important"
								v-model="user_filter"
								item-value="id"
								placeholder="Sales Person"
								@change="commanFilter($event, 'users')"
							>
								<template #selection="{ item, attrs }">
									<v-chip class="px-2" x-small v-bind="attrs" :input-value="selected">
										<v-avatar left v-if="item.profile_img">
											<v-img v-if="item.profile_img" :src="item.profile_img"></v-img>
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-avatar>

										<span class="text-uppercase fw-600">{{ item.display_name }}</span>
									</v-chip>
								</template>
								<template #item="{ item }">
									<div class="d-flex align-center py-1">
										<v-avatar class="mr-2">
											<v-img v-if="item.profile_img" :src="item.profile_img" />
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-avatar>
										<div>
											<div class="fw-600 text-uppercase">{{ item.display_name }}</div>
											<div class="fw-500 text-muted">{{ item.email }}</div>
										</div>
									</div>
								</template>
							</v-autocomplete>
						</div>
						<div class="ml-1">
							<v-btn
								depressed
								color="white"
								class="red darken-4"
								tile
								text
								small
								style="height: 26px; min-width: 0px !important"
								v-on:click="clearFliter"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>
						<template v-if="internaldata == false && getPermission('contract:create')">
							<v-btn
								depressed
								:disabled="pageLoading"
								tile
								color="red darken-4"
								class="text-white ml-2"
								v-on:click="create_contract"
							>
								<v-icon left> mdi-plus </v-icon>
								Create
							</v-btn>
						</template>

						<v-btn
							v-if="internaldata == false"
							depressed
							:disabled="pageLoading"
							color="blue darken-4"
							class="text-white"
							tile
							v-on:click="routeSetting()"
						>
							<v-icon>mdi-cog</v-icon>
						</v-btn>
						<!-- <v-btn
						v-if="internaldata == false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="drawerFilter = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn> -->

						<!-- <v-menu
							v-if="internaldata == false"
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-menu</v-icon>
								</v-btn>
							</template>

							<v-list class="py-0">
								<template v-for="(item, i) in listingAction">
									<v-list-item link :key="i" v-on:click="referess()">
										<v-list-item-title
											><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
											>{{ item.title }}</v-list-item-title
										>
									</v-list-item>
									<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
								</template>
							</v-list>
						</v-menu> -->
						<v-menu
							v-if="internaldata == false"
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-table-edit</v-icon>
								</v-btn>
							</template>
							<Draggable
								tag="ul"
								v-model="draggableThead"
								class="draggable-group"
								handle=".draggable-drag-icon"
								v-on:change="updateTable('contract')"
							>
								<template v-for="cols in draggableThead">
									<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
										<v-checkbox
											dense
											v-model="draggableTheadShow"
											v-bind:value="cols.key"
											:label="cols.name"
											:disabled="cols.fixed || pageLoading"
											color="blue"
											hide-details
											class="mt-0 mb-0"
											v-on:change="updateTableVisiblity('contract')"
										></v-checkbox>
										<v-icon
											v-if="!pageLoading && !cols.fixed"
											class="draggable-action draggable-drag-icon"
											right
											color="blue"
											>mdi-drag</v-icon
										>
										<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
									</li>
								</template>
							</Draggable>
						</v-menu>
					</v-flex>
				</template>
			</v-flex>
		</v-layout>
		<v-layout class="page-summary show" v-if="false">
			<v-flex
				:class="`summary-details ${item.color} lighten-5`"
				v-for="(item, index) in status_list"
				:key="index"
			>
				<v-icon size="30" :color="item.color">mdi-file-chart-outline</v-icon>
				<div :class="`name ${item.color}--text no-wrap`">{{ item.name }}</div>
				<div :class="`number ${item.color}--text`">{{ item.total_count }}</div>
			</v-flex>
		</v-layout>

		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Contract #, Title, Billing Type, Contract Duration"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<template v-if="addvanceFilterAarry.length > 0">
			<v-layout class="btx-search-criteria">
				<v-flex md10>
					<h6>Search Criteria</h6>

					<ul>
						<li>
							Advance Filter
							<span class="search-text"></span>
						</li>
					</ul>
				</v-flex>
				<v-flex md2 class="text-right">
					<v-btn
						depressed
						color="red lighten-1"
						class="text-white"
						tile
						text
						small
						v-on:click="statusFilterRows('all')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<Dialog :dialog="export_dialog">
			<template v-slot:title> Import Recurrings </template>
			<template v-slot:body> Export </template>
			<template v-slot:action>
				<v-btn tile depressed class="mr-3" @click="export_dialog = false">Close</v-btn>
				<v-btn tile depressed color="blue darken-4 white--text" class="">Import</v-btn>
			</template>
		</Dialog>
		<template v-if="addvanceFilterAarry.length > 0">
			<v-layout class="btx-search-criteria">
				<v-flex md10>
					<h6>Search Criteria</h6>
					<ul>
						<li>
							Advance Filter
							<span class="search-text"></span>
						</li>
					</ul>
				</v-flex>
				<v-flex md2 class="text-right">
					<v-btn
						depressed
						color="red lighten-1"
						class="text-white"
						tile
						text
						small
						v-on:click="statusFilterRows('all')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<template>
			<Table
				type="contract"
				delete-endpoint="contract/"
				detail-route="contract-detail"
				@reload:content="filterRows"
				@update:dialog="getContrectdata"
				:page-loading="pageLoading"
				v-on:openviewPage="openView($event)"
				delete-note="All transactions of this member will also be deleted."
				:dialog-update="true"
				v-on:open-dialog="getContrectdata($event)"
			>
				<!-- :is-dialog-detail="true" -->
			</Table>
		</template>
		<AdvanceFilter
			v-on:addvanceFilterData="dataFilter"
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="contract"
			search-title="contract"
		></AdvanceFilter>
		<!-- v-on:refress="(updateDialog = false), getListing()" -->

		<CreateContract
			v-if="updateDialog && getPermission('contract:create')"
			v-on:success="successCreateContract"
			@close="updateDialog = false"
			@refreshSettings="getSettings()"
			:update-dialog="updateDialog"
			:contract-id="contactId"
			:customer-id="relatedId"
			internal
			:settings="settings"
			:customer-list="customerData"
		>
		</CreateContract>
		<!-- <DetailContract
			v-on:close="DetailContractDialog = false"
			v-on:updateStatus="getListing()"
			:detail-contract-dialog="DetailContractDialog"
			:contract-id="contactId"
			:customer-id="relatedId"
		>
		</DetailContract> -->
		<!-- </v-sheet> -->
	</div>
</template>

<style>
.inline-filter .v-input__control fieldset {
	max-height: 31px !important;
}

.inline-filter .v-input__control .v-input__append-inner {
	margin-top: 2.2px !important;
	margin-bottom: 0 !important;
}
</style>

<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import HeightMixin from "@/core/mixins/height.mixin";
//import UpdateInvoice from "@/view/module/components/update-invoice";
//import DatePicker from "@/view/components/DatePicker";
/* import DetailContract from "@/view/module/contract/Detail-Contract"; */
import { ContractEventBus } from "@/core/lib/contract.lib";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
} from "@/core/services/store/listing.module";
import { /* GET, */ QUERY } from "@/core/services/store/request.module";
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import SettingsMixin from "@/core/mixins/settings.mixin.js";
import objectPath from "object-path";

export default {
	name: "Contract-listing",
	title: "Listing Contract",
	mixins: [ListingMixin, HeightMixin, SettingsMixin],
	data() {
		return {
			skipBottomHeight: 72,
			settingsEndpoint: "contract-setting",
			export_dialog: false,
			updateDialog: false,
			DetailContractDialog: false,
			users: [],
			contactId: 0,
			search_query: null,
			customerData: null,
			addvanceFilterAarry: [],
			customer_type: 0,
			user_filter: 0,
			actula_date_filter: null,
			drawerFilter: false,
			pageTitle: "Contract",
			pageBreadcrumbs: [{ text: "Contract", disabled: true }],
			endpoint: "contract",
			defaultFilter: {},
			count: {},
			pageLoading: false,
			contractStatus: [],
			status: "all",
			settings: {},
		};
	},
	props: {
		/* relatedId: {
			type: Number,
			default: 0,
		}, */
		relatedId: {
			type: Number,
			default: 0,
		},
		internaldata: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
		moduleSettings: {
			deep: true,
			handler(settings) {
				if (settings && Object.keys(settings).length) {
					this.contractStatus = settings.status;
					if (Array.isArray(settings.users)) {
						this.users = settings.users.filter((user) => user.users_type == "sales");
					} else {
						this.users = [];
					}
					this.settings = settings;
				}
			},
		},
	},
	methods: {
		getContrectdata(id) {
			this.updateDialog = true;
			this.contactId = id;
		},
		openView(id) {
			this.DetailContractDialog = true;
			this.contactId = id;
		},
		create_contract() {
			this.contactId = 0;
			this.updateDialog = true;
		},
		dataFilter(value) {
			this.addvanceFilterAarry = value;
		},
		referess() {
			window.location.reload();
		},
		getCustomerData() {
			this.$store
				.dispatch(QUERY, {
					url: `customer-list`,
					data: { status: "active" },
				})
				.then((data) => {
					if (data && data.length) {
						this.customerData = data;
					} else {
						this.customerData = [];
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		clearFliter() {
			(this.customer_type = null),
				(this.user_filter = null),
				(this.defaultFilter = {}),
				this.$nextTick(() => {
					this.getListing();
				});
			this.$router.replace({
				name: "contract",
				query: { t: new Date().getTime() },
			});
		},
		// getContactStatus() {
		// 	this.$store
		// 		.dispatch(GET, { url: "contract-setting" })
		// 		.then((data) => {
		// 			this.contractStatus = data.status;
		// 			this.users = data.users;
		// 		})
		// 		.catch((error) => {
		// 			console.log({ error });
		// 		})
		// 		.finally(() => {
		// 			this.pageLoading = false;
		// 		});
		// },
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "contract",
				},
			});
		},
		statusFilterRows(value) {
			this.pageLoading = true;

			let payload = {
				status: value,
				related_id: this.relatedId,
				user_filter: this.user_filter ? this.user_filter : null,
				customer_type: this.customer_type ? this.customer_type : null,
			};

			this.allFiltersStatus(payload);

			this.$store
				.dispatch(QUERY, {
					url: `contract`,
					data: { ...payload },
				})
				.then((data) => {
					this.getcurrenttype = data.filter[0].type;
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					this.addvanceFilterAarry = [];
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		commanFilter() {
			this.pageLoading = true;
			let query = this.$route.query;
			let status = +query?.status ? +query?.status : "all";
			this.allFiltersSetting(status);
			this.$store
				.dispatch(QUERY, {
					url: `contract`,
					data: {
						status,
						user_filter: this.user_filter ? this.user_filter : null,
						customer_type: this.customer_type ? this.customer_type : null,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersSetting(status) {
			this.$router.push({
				name: "contract",
				query: {
					status,
					user_filter: this.user_filter ? this.user_filter : null,
					customer_type: this.customer_type ? this.customer_type : null,
					t: new Date().getTime(),
				},
			});
		},
		allFiltersStatus(payload) {
			this.$router.push({
				name: "contract",
				query: {
					...payload,
					t: new Date().getTime(),
				},
			});
		},
		successCreateContract() {
			this.$store.commit(SET_CURRENT_PAGE, 1);
			// let query = this.$route.query;
			this.customer_type = null;
			this.user_filter = null;
			this.$router.push({
				name: "contract",
				query: {
					status: "all",
					page: 1,
					t: new Date().getTime(),
				},
			});
			this.getListing();
			this.updateDialog = false;
		},
	},
	computed: {
		...mapGetters(["currentUser", "moduleSettings", "currentUserPermissions"]),
	},
	beforeMount() {
		this.user_filter = toSafeInteger(this.$route.query.user_filter);
		this.customer_type = toSafeInteger(this.$route.query.customer_type);
		if (
			(this.currentUser.role == 1 ||
				this.currentUser.role == 4 ||
				this.currentUser.role == 6 ||
				this.currentUser.role == 7 ||
				this.currentUser.role == 12 ||
				this.currentUser.role == 13) &&
			!this.user_filter
		) {
			this.user_filter = this.$route.query.user_filter;
		}
		/* this.defaultFilter = {
			user_filter: this.user_filter ? this.user_filter : null,
			customer_type: this.customer_type ? this.customer_type : null,
		}; */
	},
	components: {
		/* 	DetailContract, */

		Dialog: () => import("@/view/components/Dialog"),
		CreateContract: () => import("@/view/module/contract/Create-Contract"),
		AdvanceFilter: () => import("@/view/pages/leads/AdvanceFilter"),
	},
	mounted() {
		this.getCustomerData();
		// this.getContactStatus();
		let filterQuery = this.$route.query;
		this.status = objectPath.get(filterQuery, "status") || "all";
		ContractEventBus.$on("parent-refresh", () => {
			this.successCreateContract();
			/* this.getListing();
			this.updateDialog = false; */
		});
		ContractEventBus.$on("settings-refresh", () => {
			console.log("settings-refresh");
			this.getSettings();
		});
		this.$router.push({
			name: "contract",
			query: {
				...filterQuery,
				t: new Date().getTime(),
			},
		});
	},
};
</script>
